/*
 * Next router.events
 * https://nextjs.org/docs/api-reference/next/router#routerevents
 */
export const NEXT_ROUTER_EVENT_ROUTE_CHANGE_START = 'routeChangeStart'
export const NEXT_ROUTER_EVENT_ROUTE_CHANGE_ERROR = 'routeChangeError'

/*
 * Validation
 */
export const VALIDATE_EMAIL_DEBOUNCE = 750
export const VALIDATE_PHONE_LENGTH_MIN = 8
export const VALIDATE_PHONE_LENGTH_MAX = 15
export const VALIDATE_PHONE_REGEX = /^0[ \d]+$/ // NOTE: Include space in phone number
export const VALIDATE_POSTCODE_LENGTH_MIN = 5
export const VALIDATE_POSTCODE_LENGTH_MAX = 8
export const VALIDATE_ADDRESS_LINE_LENGTH_MIN = 3
export const VALIDATE_ADDRESS_LINE_LENGTH_MAX = 50

/*
 * Tokens
 */
export const TOKEN_TIMESTAMP_MULTIPLIER = 1000

/*
 * Contact numbers
 */

// keeping `tel:` in the string allows us to switch
// it out for another scheme if we need to e.g. `skype:`
export const TELEPHONE_HREF = `tel:+442039500455`
export const TELEPHONE_PRETTY = `020 3950 0455`

/*
 * Contact email
 */
export const CONTACT_EMAIL = 'hello@mindfulchef.com'

/*
 * Polling
 */
export const PAYMENT_POLLING_FREQUENCY = 2 * 1000
export const POLLING_TIMEOUT = 60 * 1000

/*
 * My account
 */
export const CUSTOMER_PARTNERSHIP_KEY = 'customerPartnershipKey'
export const CUSTOMER_ID_COOKIE_KEY = 'customerId'
export const ACCESS_TOKEN_KEY = '_ga_analytics'
export const ORIGIN_KEY = 'MindfulChef-API'
export const DATE_FORMAT = 'iiii do MMMM'
export const DATE_FORMAT_COMPARISONS = 'yyyy-MM-dd'
export const DELIVERY_EDIT_GRACE_PERIOD = parseFloat(
  process.env.NEXT_PUBLIC_RECIPES_GRACE_PERIOD
)

export const TOTAL_RECIPES_AVAILABLE_PER_WEEK =
  process.env.NEXT_PUBLIC_TOTAL_RECIPES_PER_WEEK
export const SAFE_PLACE_CHARACTER_LIMIT = 50

export const SEEN_PERSONALISATION_SURVEY = 'seenPersonalisationSurvey'

/**
 * Payment
 */
export const PAYMENT_DATE_DAYS_BEFORE_DELIVERY = 3

/*
 * Imgix
 */

export const IMAGE_SIZE_LABELS = ['sm', 'md', 'lg', 'xl']

// Use for non recipe images
export const IMAGE_PATH_PREFIX =
  'https://mindfulchef-images-production.imgix.net/'

// Default image, if none is provided (NOTE: must be uploaded to AWS s3)
export const DEFAULT_IMAGE = `${IMAGE_PATH_PREFIX}mc-monogram.gif`

/*
 * JWT token key
 */
export const AUTH_TOKEN = 'authToken'

// Feature flags
export const FEATURE_FLAGS_KEY = 'features'

// Recipe Portions
export const PORTION_COUNT_DEFAULT = 2
export const PORTION_FAMILY = 4
export const PORTION_DOUBLE = 2

export const PORTION_SINGLE = 1
export const PORTION_SIZES = [PORTION_SINGLE, PORTION_DOUBLE, PORTION_FAMILY]

// Recipe's Minimum and Maximum values
export const RECIPE_MIN_SINGLE = 3
export const RECIPE_MIN_DEFAULT = 2
export const RECIPE_MAX_FAMILY = 4
export const RECIPE_MAX_DEFAULT = 5

// Analytics
type ValueOf<T> = T[keyof T]
export type ANALYTICS_CATEGORIES_VALUES = ValueOf<typeof ANALYTICS_CATEGORIES>
export const ANALYTICS_CATEGORIES = {
  buttonClick: 'ButtonClick',
  checkoutAboutYou: 'Checkout - About You',
  checkoutPayment: 'Checkout - Payment',
  checkoutShipping: 'Checkout - Shipping',
  checkoutSuccess: 'Checkout - Success',
  chooseRecipes: 'Choose Recipes',
  exposingBasket: 'Exposing basket test',
  global: 'Global',
  homepage: 'Homepage',
  myAccount: 'My Account',
  myAccountProducts: 'My account - Products',
  myAccountProductDetails: 'My account - Product details',
  myAccountBasket: 'My account - Basket',
  MyAccountDetails: 'My account - Details',
  calendar: 'Calendar',
  navigation: 'Navigation',
  onboarding: 'Onboarding',
  recipe: 'Recipe',
  signIn: 'Sign In',
  recipeLibrary: 'Recipe Library',
  carbonLabel: 'Carbon Label',
  lastMinuteDelivery: 'Last Minute Delivery',
  shopProducts: 'Shop - Products',
  shopProductDetails: 'Shop - Product details',
  shopBasket: 'Shop - Basket',
  shopAboutYou: 'Shop - About you',
  shopShipping: 'Shop - Shipping',
  vitalitySignIn: 'vitality signin page',
  vitalitySignUp: 'vitality signup page',
} as const

// redux - async keys
export const IS_FETCHED = 'isFetched'
export const IS_PENDING = 'isPending'
export const ERROR_MESSAGE = 'errorMessage'

// Loqate types
export const LOQATE_TYPE_COMMERCIAL = 'Commercial'
export const LOQATE_TYPE_RESIDENTIAL = 'Residential'

// Onboarding
export const DROPDOWN_CLOSING_DELAY = 300

// Search
export const SEARCH_FILTER_COOKINGTIME = 'cook_time_minutes'
export const SEARCH_FILTER_COOKINGTIME_SPEEDY = '10:15'
export const SEARCH_FILTER_COOKINGTIME_MODERATE = '15:25'
export const SEARCH_FILTER_CUISINE = 'cuisine'
export const SEARCH_FILTER_CUISINE_LIMIT = 25
export const SEARCH_FILTER_PRIMARY_FOOD = 'primary_food_group'
export const SEARCH_FILTER_RATING = 'rating_average'
export const SEARCH_FILTER_RATING_POPULAR = '4:'
export const SEARCH_FILTER_SEASON = 'season'
export const SEARCH_FILTER_SEASON_WINTER = 'Winter'

// Meal plans
export const MEAL_PLAN_BALANCED = 'Balanced'
export const MEAL_PLAN_PROTEIN_PACKED = 'Protein-Packed'
export const MEAL_PLAN_PESCETARIAN = 'Pescetarian'
export const MEAL_PLAN_PLANT_BASED = 'Plant-Based'

// Proteins
export const PROTEIN_BEEF = 'Beef'
export const PROTEIN_FISH = 'Fish'
export const PROTEIN_LAMB = 'Lamb'
export const PROTEIN_PORK = 'Pork'
export const PROTEIN_POULTRY = 'Poultry'
export const PROTEIN_SHELLFISH = 'Shellfish'
export const PROTEIN_VEGAN = 'Vegan'
export const PROTEIN_DAIRY = 'Dairy'

// Postcode length
export const POSTCODE_LENGTH_TO_PAD = 5

// Past order range - days in past you can view
export const PAST_ORDER_RANGE = 180
// Order range - days in future you can order
export const ORDER_RANGE = 56

// GiftCard Payment Preview/Transaction template prepend
export const PAYMENT_GIFT_CARD_ORDER_PREPEND =
  'gid://mindful-chef-api/Vouchers::GiftCard/'

// Steps in the gift card process
export const GIFTCARDS_STEPS = {
  RECIPIENT_DETAILS: 0,
  PAYMENT_DETAILS: 1,
  CONFIRMATION: 2,
}

// card details
export const CC_CARD_NUMBER = 'card-number'
export const CC_CVV = 'cvv'
export const CC_EXPIRY_DATE = 'expiry-date'
export const CC_ACTIVE = 'active'
export const CC_ERROR = 'error'
export const CC_VALID = 'valid'
export const CC_LOCKED = 'locked'

// carbon ratings
export const CARBON_LABEL = 'Low carbon impact'

// Recipes - shipping
export const RECIPES_SHIPPING_PRICE = parseFloat(
  process.env.NEXT_PUBLIC_RECIPE_SHIPPING_PRICE
)

// messages
export const GENERIC_ERROR_MESSAGE = 'Something went wrong'

// React Query
export const STALE_10_MINS = 60 * 10 * 1000
export const STALE_15_MINS = 60 * 15 * 1000
export const STALE_1_HOUR = 60 * 60 * 1000
export const MC_POSTCODE = 'SW18 1UY'

// External URLS
export const CONTACT_US_URL =
  'https://help.mindfulchef.com/en/articles/98-contact-us' // If changing this, please update const by same name next.config.js
